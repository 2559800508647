import React, { useEffect, useState, useRef } from "react";
import "./MobileApp.css";
import rgbanner from "./images/TheRoundGuysLogo.png";
import finkels from "./images/finkels-background.jpeg";
import kyle from "./images/kyle-avatar.png";
import caleb from "./images/calebavatar.png";
import mintUp from "./images/Mint.png";
import mintDown from "./images/Mint-Down.png";
import minusUp from "./images/Minus.png";
import minusDown from "./images/Minus_Down.png";
import plusUp from "./images/Plus.png";
import plusDown from "./images/Plus_Down.png";
const MobileApp = () => {
  const [animationState, setAnimationState] = useState({
    classNames: "",
    reverseAnimationMobile: false,
  });
  const [hideClass, setHideClass] = useState("");
  const [mintClicked, setMintClicked] = useState(false);
  const [decrementClicked, setDecrementClicked] = useState(false);
  const [incrementClicked, setIncrementClicked] = useState(false);
  const [mintAmount, setMintAmount] = useState(1);
  const [calcMessage, setCalcMessage] = useState("1234 / 1234");

  const startStopAnimation = (e) => {
    const selectedTab = e.target.name;
    const { classNames, reverseAnimationMobile } = { ...animationState };
    if (!reverseAnimationMobile) {
      setAnimationState((prev) => {
        return {
          [selectedTab]: selectedTab,
          classNames: "animationMobile",
          reverseAnimationMobile: !prev.reverseAnimationMobile,
        };
      });
    }

    if (reverseAnimationMobile) {
      if (hideClass === "hideElement") setHideClass("");
      setAnimationState((prev) => {
        return {
          ...prev,
          classNames: "animationReverseMobile",
          reverseAnimationMobile: !prev.reverseAnimationMobile,
        };
      });
    }
  };

  const onAnimationEnd = () => {
    if (hideClass === "" && animationState.classNames === "animationMobile") {
      setHideClass("hideElement");
      document.body.style.position = "fixed";
    } else if (
      hideClass === "" &&
      animationState.classNames === "animationReverseMobile"
    ) {
      document.body.style.position = "static";
    }
  };

  const handleIncrement = (e) => {
    e.preventDefault();
    const maxAmount = 3;
    if (mintAmount < maxAmount) setMintAmount((prev) => prev + 1);
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    if (mintAmount > 1) setMintAmount((prev) => prev - 1);
  };

  const RoadMap = () => (
    <div className="roadmap-main roadmap-main-mobile">
      <div className="roadmap-container">
        <div className="roadmap-strip">
          <div className="roadmap-strip-head roadmap-mobile-head">Phase 1</div>
          <div className="roadmap-strip-subHead roadmap-mobile-subhead">
            Pre-Mint
          </div>
          <div className="roadmap-strip-desc roadmap-strip-mobile-desc">
            <p>
              Qualify for the whitelist through participating in our community
              contests & giveaways.
            </p>

            <p>
              To avoid gas wars, only people on the whitelist will be able to
              mint a Round Guy.
            </p>
          </div>
        </div>
        <div className="roadmap-strip">
          <div className="roadmap-strip-head roadmap-mobile-head">Phase 2</div>
          <div className="roadmap-strip-subHead roadmap-mobile-subhead">
            During-Mint
          </div>
          <div className="roadmap-strip-desc roadmap-strip-mobile-desc">
            <h2>30%</h2>{" "}
            <span>
              Giveaway 10 random round guys to 10 lucky round guy owners!
            </span>
            <h2>60%</h2>
            <span>
              Giveaway 5 RARE round guys to 5 lucky round guys owners!
            </span>
            <h2>90%</h2>
            <span>
              Giveaway 1 LEGENDARY round guy to 1 very lucky round guy owner!
            </span>
          </div>
        </div>
        <div className="roadmap-strip">
          <div className="roadmap-strip-head roadmap-mobile-head">Phase 3</div>
          <div className="roadmap-strip-subHead roadmap-mobile-subhead">
            Post-Mint
          </div>
          <div className="roadmap-strip-desc roadmap-strip-mobile-desc">
            <p>
              The Round Guys Team is excited to announce that we've connected
              with several renowned street artists throughout the U.S. to bring
              their amazing art to the blockchain.
            </p>
            <p>
              We will be working with these artists to create three 1 for 1 NFT
              collections that will each be airdropped to Round Guys holders.{" "}
            </p>
            <p>
              Each subsequent airdrop will deploy every month following the
              initial sellout of The Round Guys. That being said, we're excited
              to introduce you to the first airdrop on the lineup...
            </p>
          </div>
        </div>
        <div className="roadmap-strip">
          <h2 className="airdrop-header">Airdrop One:</h2>
          <h1 className="airdrop-title airdrop-title-mobile">Finkels</h1>
          <div className="roadmap-strip-desc roadmap-strip-mobile-desc">
            <div className="airdrop-content-container airdrop-content-container-mobile">
              <div className="finkels-img finkels-img-mobile">
                <img width="100%" src={finkels} />
              </div>
            </div>
            <p>
              Finkels is a generative art collection of 1,234 unique NFTs
              created by renowned artist Patrick Hofmeister.
            </p>

            <p>
              Patrick is a professional artist with over 20 years of experience
              working in various mediums, focusing on murals and street art. His
              work has been featured in musuems, magazines, and private art
              collections.
            </p>

            <a
              className="art-url"
              href="https://www.hofmeisterart.com/"
              target="_blank"
            >
              hofmesiterart.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {animationState["roadmap-tab"] && (
        <div
          onAnimationEnd={onAnimationEnd}
          className={`roadmap-dropdown-mobile ${animationState.classNames}`}
        >
          <div className="closeContainerMobile">
            <button onClick={startStopAnimation} className="closeMobile">
              ✕
            </button>
          </div>
          {/* <ol className={`roadmap-list ${animationState.classNames}`}>
            <li>
              <a onClick={() => executeScroll(1)}>Pre-Mint</a>
            </li>
            <li>
              <a onClick={() => executeScroll(2)}>Mint</a>
            </li>
            <li>
              <a onClick={() => executeScroll(3)}>Sellout</a>
            </li>
            <li>
              <a onClick={() => executeScroll(4)}>Airdrops</a>
            </li>
            <li>
              <a onClick={() => executeScroll(5)}>DAO</a>
            </li>
            a
          </ol> */}
          <div className="roadmap-details roadmap-details-mobile">
            <RoadMap />
            {/* <Airdrops /> */}
            <div className="roadmap-main roadmap-main-mobile">
              <div className="roadmap-container">
                <div className="roadmap-strip ">
                  <div className="roadmap-strip-head roadmap-mobile-head">
                    Phase 4
                  </div>
                  <div className="roadmap-strip-subHead roadmap-mobile-subhead">
                    Collective Art DAO
                  </div>
                  <div className="roadmap-strip-desc  roadmap-strip-mobile-desc">
                    <p>
                      We will begin building a revolutionary DAO that will help
                      finance traditional artists and bridge physical artwork to
                      the digital world.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {animationState["mint-tab"] && (
        <div
          onAnimationEnd={onAnimationEnd}
          className={`roadmap-dropdown-mobile ${animationState.classNames}`}
        >
          <div className="closeContainerMobile">
            <button onClick={startStopAnimation} className="closeMobile">
              ✕
            </button>
          </div>
          <div className=" mint-section-wrapper mint-section-wrapper-mobile">
            <div className="mint-top mint-top-mobile">
              <div
                style={{
                  flexDirection: "column",
                  marginLeft: "2rem",
                  marginRight: "2rem",
                }}
                className="mint-top-content mint-top-content-mobile"
              >
                <div className="roadmap-strip-head roadmap-mobile-head"></div>
                <div
                  style={{ marginBottom: "10px" }}
                  className="roadmap-strip-subHead roadmap-mobile-subhead"
                >
                  Mint Details
                </div>
                <div className="roadmap-strip-desc  roadmap-strip-mobile-desc">
                  <div style={{ marginBottom: "12px" }}>
                    <div>Total Supply:</div>
                    <div>1,234</div>
                  </div>
                  <div style={{ marginBottom: "12px" }}>
                    <div>Unique Assets:</div>
                    <div>100+</div>
                  </div>
                  <div>
                    <div>Price:</div>
                    <div>0.05 ETH + GAS</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {animationState["team-tab"] && (
        <div
          onAnimationEnd={onAnimationEnd}
          className={`roadmap-dropdown roadmap-team-bg-mobile ${animationState.classNames}`}
        >
          <div className="closeContainerMobile">
            <button onClick={startStopAnimation} className="closeMobile">
              ✕
            </button>
          </div>
          <div className="teamContainerMobile">
            <div className="teamAvatarMobile">
              <div>
                <img src={kyle}></img>
              </div>
              <div className="teamContentMobile">
                <span>Kyle Boyea</span>
                <a
                  className="twitter-link"
                  target="_blank"
                  href="https://twitter.com/uneeddabae"
                >
                  @uneeddabae
                </a>
                <span>Artist / Developer</span>
              </div>
            </div>

            <div className="teamAvatarMobile">
              <div>
                <img src={caleb}></img>
              </div>
              <div className="teamContentMobile">
                <span>Caleb Fowler</span>
                <a
                  target="_blank"
                  className="twitter-link"
                  href="https://twitter.com/ctfcaleb"
                >
                  @ctfcaleb
                </a>
                <span>Developer / Marketing</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* -------------------------------- NAVIGATION BAR -------------------------------- */}
      <nav>
        <div className="mobileContainer">
          <a
            name="roadmap-tab"
            onClick={startStopAnimation}
            className="mobileNavItem"
          >
            ROADMAP
          </a>
          <a
            name="mint-tab"
            onClick={startStopAnimation}
            className="mobileNavItem"
          >
            MINT
          </a>

          <a
            name="team-tab"
            onClick={startStopAnimation}
            className="mobileNavItem"
          >
            TEAM
          </a>

          <div className="social-links-nav">
            <a target="_blank" href="https://discord.gg/ycUSZvYTDX">
              {/* <img className="social-img" src={discord} /> */}
            </a>
            <a
              target="_blank"
              href="https://twitter.com/TheRoundGuysNFT"
              style={{ padding: "0vw 1vw" }}
            >
              {/* <img className="social-img twitter-img" src={twitter} /> */}
            </a>
          </div>
        </div>
      </nav>

      {/* -------------------------------- HERO SECTION -------------------------------- */}
      <div className="clouds-gif">
        {/* <div className="dot"></div> */}

        <div className="landing-page-container">
          <img
            // onMouseEnter={showRoundGuy}
            // onMouseLeave={hideRoundGuy}
            id="mainLogoMobile"
            src={rgbanner}
          />
          <div className="mobileLandingTextContainer">
            The Round Guys is the genesis project of a series of NFT collections
            to come. Owning a Round Guy gains you exclusive access to future
            airdrops from renowned street artists.
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileApp;

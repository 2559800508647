import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./fonts/font.css";
import App from "./App";
import MobileApp from "./MobileApp";
import reportWebVitals from "./reportWebVitals";

// const useIsMobile = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth <= 736);
//   const updateIsMobile = () => {
//     setIsMobile(window.innerWidth <= 768);
//   };
//   useEffect(() => {
//     window.addEventListener("resize", updateIsMobile);
//     return () => {
//       window.removeEventListener("resize", updateIsMobile);
//     };
//   }, []);

//   return [isMobile];
// };

ReactDOM.render(
  <React.StrictMode>
    {window.innerWidth <= 736 ? <MobileApp /> : <App />}
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useState, useRef } from "react";
import "./App.css";
//import RoundGuyContract from "./contracts/RoundGuys.sol/RoundGuys.json";
import getWeb3 from "./getWeb3";
import rg1 from "./images/rg1.png";
import rg2 from "./images/bubble.png";
import rg3 from "./images/RoundGuysLayers.png";
import ABE from "./images/abraham.jpeg";
import ARETHA from "./images/aretha.jpeg";
import LEE from "./images/leehavy.jpeg";
import rg4 from "./images/rgdefault.png";
import rgbanner from "./images/TheRoundGuysLogo.png";
// import rgflipper from "./images/rg-flipper.gif";
import space from "./images/Space.gif";
import paper from "./images/BinderPaper1.png";
import ground from "./images/footer.png";
import rgKyle from "./images/rg_roshi_frame.png";
import rgCaleb from "./images/rg_sixfigs_frame.png";
import team from "./images/Team.png";
import priceImg from "./images/MintPrice.png";
import supplyImg from "./images/Supply.png";
import traitsImg from "./images/Traits.png";
import twitter from "./images/twitter.png";
import discord from "./images/discord.png";
import dither from "./images/dither_blue.png";
import rgflipper from "./images/roundguysflipper.gif";
import calculator from "./images/Calculator.png";
import mintUp from "./images/Mint.png";
import mintDown from "./images/Mint-Down.png";
import minusUp from "./images/Minus.png";
import minusDown from "./images/Minus_Down.png";
import plusUp from "./images/Plus.png";
import plusDown from "./images/Plus_Down.png";
import roundguysbanner from "./images/roundguysbannerinfinite.png";
import finkelsTransparent from "./images/finkels-transparent.png";
import finkels from "./images/finkels-background.jpeg";

// UPDATE THIS WHEN DEPLOYING CONTRACT EVERYTIME IT WILL BE DIFFERENT FOR LOCAL HOST
// LOCALHOST CONTRACT ADDRESS
// const roundGuysAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3";
// ROPSTEN TEST NET CONTRACT ADDRESS
const roundGuysAddress = "0x652628434a3af784a0a797cec7be6377ecb7a67c";

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 736);
  const updateIsMobile = () => {
    setIsMobile(window.innerWidth <= 768);
  };
  useEffect(() => {
    window.addEventListener("resize", updateIsMobile);
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  return [isMobile];
};

function App() {
  const [isEtherium, setIsEtherium] = useState(true);
  const [isTestNet, setIsTestNet] = useState(true);
  const [totalSupply, setTotalSupply] = useState(0);
  const [contractBalance, setContractBalance] = useState("");

  const [isMinting, setIsMinting] = useState(false);
  const [mintError, setMintError] = useState(null);
  const [mintCount, setMintCount] = useState(1);
  const [mintClicked, setMintClicked] = useState(false);
  const [decrementClicked, setDecrementClicked] = useState(false);
  const [incrementClicked, setIncrementClicked] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState("Undetected");
  const [address, setAddress] = useState("");
  const [accounts, setAccounts] = useState("");
  const [contract, setContract] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [mintAmount, setMintAmount] = useState(1);
  const [calcMessage, setCalcMessage] = useState("1234 / 1234");
  const [isMobile] = useIsMobile();

  const [animationState, setAnimationState] = useState({
    classNames: "",
    reverseAnimation: false,
  });

  const [roundGuyAnimation, setRoundGuyAnimation] = useState({
    classNames: "",
  });

  const [hideUI, setHideUI] = useState(false);
  const [hideClass, setHideClass] = useState("");

  const first = useRef(null);
  const second = useRef(null);
  const third = useRef(null);
  const fourth = useRef(null);
  const fifth = useRef(null);

  const executeScroll = (num) => {
    if (num === 5) fifth.current.scrollIntoView();
    if (num === 4) fourth.current.scrollIntoView();
    if (num === 3) third.current.scrollIntoView();
    if (num === 2) second.current.scrollIntoView();
    if (num === 1) first.current.scrollIntoView();
    if (!num) first.current.scrollIntoView();
  };
  // const functions = getFunctions(app);

  // useEffect(() => {
  //   (async () => {
  //     // Get provider
  //     const web3 = await getWeb3();

  //     // Current network name (not 100% accurate just helps with debugging on front end for now)
  //     const currentNetworkName = await web3.eth.net.getNetworkType();

  //     // Get current chainID (this is identifier for which network you are using and can be mapped to a network name)
  //     const chainId = await web3.eth.getChainId();

  //     // Checks if network is etherium or hardhat / ganache test environment
  //     const isEtherium = chainId === 1;

  //     // Checks if network is on valid testnet
  //     const validTestNets = [1337, 3, 42, 4, 5];
  //     const isTestNet = validTestNets.includes(chainId);

  //     // Get connected accounts array
  //     const accounts = await web3.eth.getAccounts();

  //     // create contract instance
  //     const contract = new web3.eth.Contract(
  //       RoundGuyContract.abi,
  //       roundGuysAddress
  //     );

  //     setWeb3(web3);
  //     setAccounts(accounts);
  //     setAddress(accounts[0]);
  //     setContract(contract);
  //     setIsEtherium(isEtherium);
  //     setIsTestNet(isTestNet);
  //     setCurrentNetwork(currentNetworkName);
  //   })();
  // }, []);

  // useEffect(() => {
  //   window.ethereum.on("chainChanged", (_chainId) => window.location.reload());
  //   window.ethereum.on("accountsChanged", (_chainId) =>
  //     window.location.reload()
  //   );
  // }, []);

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       // If the element is visible
  //       if (entry.isIntersecting) {
  //         // Add the animation class
  //         entry.target.classList.add("airdrop-animation");
  //       }
  //     });
  //   });
  //   observer.observe(document.querySelector(".airdrop-title"));
  // }, []);

  const updateTotalSupply = async () => {
    try {
      const ts = await contract.methods.totalSupply().call();
      setTotalSupply(ts);
    } catch (error) {
      console.log(error);
    }
  };

  const updateContractBalance = async () => {
    try {
      const balanceInGwei = await web3.eth.getBalance(roundGuysAddress);
      const balanceInEth = web3.utils.fromWei(balanceInGwei, "ether");
      setContractBalance(balanceInEth);
    } catch (error) {
      console.log(error);
    }
  };

  const setBaseTokenURI = async () => {
    try {
      await contract.methods.setBaseTokenURI(
        "https://theroundguys.web.app/api/"
      );
      console.log("base token uri set");
    } catch (error) {
      console.log(error);
    }
  };

  async function handleMint() {
    // BASE MINT PRICE * NUMBER OF MINTS
    const mintPrice = 0.04;
    const totalCost = (mintPrice * mintCount).toString();

    // GET USER BALANCE IN ETH
    const userBalanceInGwei = await web3.eth.getBalance(address);
    const userBalanceInEth = web3.utils.fromWei(userBalanceInGwei, "ether");

    // CHECK IF USER HAS REQUIRED BALANCE TO MINT X AMOUNT OF GUYS
    if (parseInt(userBalanceInEth) < parseInt(totalCost)) {
      alert(`Not enough ETH to mint ${mintCount} guy(s) for ${totalCost}`);
      return;
    }

    // ATTEMPT TO MINT ROUND GUY
    try {
      setIsMinting(true);
      await contract.methods
        .mintNFT(mintCount)
        .send({
          from: address,
          value: web3.utils.toWei(mintPrice.toString(), "ether"),
        })
        .on("error", (err) => {
          setMintError(
            "There was a problem minting your round guy, please refresh and try again."
          );
          console.error(err);
        })
        .on("receipt", (res) => {
          console.log("succesfully minted");
        });
    } catch (err) {
      setMintError(
        "There was a problem minting your round guy, please refresh and try again."
      );
      console.log(err);
    } finally {
      setIsMinting(false);
      // update total supply in UI
      updateTotalSupply();
      updateContractBalance();
    }
  }

  const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
      <iframe
        width="500"
        height="313"
        src="https://www.youtube.com/embed/EHqsCmfTDmI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );

  const RoadMap = () => (
    <div className="roadmap-main">
      <div className="roadmap-container">
        <div ref={first} className="roadmap-strip">
          <div className="roadmap-strip-head">Phase 1</div>
          <div className="roadmap-strip-subHead">Pre-Mint</div>
          <div className="roadmap-strip-desc">
            <p>
              Qualify for the whitelist through participating in our community
              contests & giveaways.
            </p>

            <p>
              To avoid gas wars, only people on the whitelist will be able to
              mint a Round Guy.
            </p>
          </div>
        </div>
        <div ref={second} className="roadmap-strip">
          <div className="roadmap-strip-head">Phase 2</div>
          <div className="roadmap-strip-subHead">During-Mint</div>
          <div className="roadmap-strip-desc">
            <h2 style={{ fontSize: "2vw" }}>30%</h2>{" "}
            <span>
              Giveaway 10 random round guys to 10 lucky round guy owners!
            </span>
            <h2 style={{ fontSize: "2vw" }}>60%</h2>
            <span>
              Giveaway 5 RARE round guys to 5 lucky round guys owners!
            </span>
            <h2 style={{ fontSize: "2vw" }}>90%</h2>
            <span>
              Giveaway 1 LEGENDARY round guy to 1 very lucky round guy owner!
            </span>
          </div>
        </div>
        <div ref={third} className="roadmap-strip">
          <div className="roadmap-strip-head">Phase 3</div>
          <div className="roadmap-strip-subHead">Post-Mint</div>
          <div className="roadmap-strip-desc">
            <p>
              The Round Guys Team is excited to announce that we've connected
              with several renowned street artists throughout the U.S. to bring
              their amazing art to the blockchain.
            </p>
            <p>
              We will be working with these artists to create three 1 for 1 NFT
              collections that will each be airdropped to Round Guys holders.{" "}
            </p>
            <p>
              Each subsequent airdrop will deploy every month following the
              initial sellout of The Round Guys. That being said, we're excited
              to introduce you to the first airdrop on the lineup...
            </p>
          </div>
        </div>
        <div ref={fourth} className="roadmap-strip">
          <h2 className="airdrop-header">Airdrop One:</h2>
          <h1 className="airdrop-title">Finkels</h1>
          <div className="roadmap-strip-desc">
            <div className="airdrop-content-container">
              <div className="finkels-img">
                <img width="100%" src={finkels} />
              </div>
            </div>
            <p>
              Finkels is a generative art collection of 1,234 unique NFTs
              created by renowned artist Patrick Hofmeister.
            </p>

            <p>
              Patrick is a professional artist with over 20 years of experience
              working in various mediums, focusing on murals and street art. His
              work has been featured in musuems, magazines, and private art
              collections.
            </p>

            <a
              className="art-url"
              href="https://www.hofmeisterart.com/"
              target="_blank"
            >
              hofmeisterart.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {});

  const startStopAnimation = (e) => {
    const selectedTab = e.target.name;
    const { classNames, reverseAnimation } = { ...animationState };
    if (!reverseAnimation) {
      setAnimationState((prev) => {
        return {
          [selectedTab]: selectedTab,
          classNames: "animation",
          reverseAnimation: !prev.reverseAnimation,
        };
      });
    }

    if (reverseAnimation) {
      if (hideClass === "hideElement") setHideClass("");
      setAnimationState((prev) => {
        return {
          ...prev,
          classNames: "animationReverse",
          reverseAnimation: !prev.reverseAnimation,
        };
      });
    }
  };

  const onAnimationEnd = () => {
    if (hideClass === "" && animationState.classNames === "animation") {
      setHideClass("hideElement");
      document.body.style.position = "fixed";
    } else if (
      hideClass === "" &&
      animationState.classNames === "animationReverse"
    ) {
      document.body.style.position = "static";
    }
  };

  const handleIncrement = (e) => {
    e.preventDefault();
    const maxAmount = 3;
    if (mintAmount < maxAmount) setMintAmount((prev) => prev + 1);
  };

  const handleDecrement = (e) => {
    e.preventDefault();
    if (mintAmount > 1) setMintAmount((prev) => prev - 1);
  };

  const showRoundGuy = () => {
    setRoundGuyAnimation((prev) => {
      return {
        ...prev,
        classNames: "showRoundGuy",
      };
    });
  };

  const hideRoundGuy = () => {
    setRoundGuyAnimation((prev) => {
      return {
        ...prev,
        classNames: "hideRoundGuy",
      };
    });
  };

  const Roadmaptab = () => {
    return (
      <>
        <div className="closeContainer">
          <button onClick={startStopAnimation} className="close">
            ✕
          </button>
        </div>
        <ol className={`roadmap-list ${animationState.classNames}`}>
          <li>{/* <a onClick={() => executeScroll(1)}>Pre-Mint</a> */}</li>
          <li>
            <a onClick={() => executeScroll(2)}>Mint</a>
          </li>
          <li>
            <a onClick={() => executeScroll(3)}>Sellout</a>
          </li>
          <li>
            <a onClick={() => executeScroll(4)}>Airdrops</a>
          </li>
          <li>
            <a onClick={() => executeScroll(5)}>DAO</a>
          </li>
          a
        </ol>
        <div className="roadmap-details">
          <RoadMap />
          {/* <Airdrops /> */}
          <div className="roadmap-main">
            <div className="roadmap-container">
              <div className="roadmap-strip">
                <div className="roadmap-strip-head">Phase 4</div>
                <div ref={fifth} className="roadmap-strip-subHead">
                  COLLECTIVE ART DAO
                </div>
                <div className="roadmap-strip-desc">
                  <p>
                    We will begin building a revolutionary DAO that will help
                    finance traditional artists and bridge physical artwork to
                    the digital world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* -------------------------------- ROADMAP DROPDOWN  -------------------------------- */}
      <>
        {animationState["roadmap-tab"] && (
          <div
            onAnimationEnd={onAnimationEnd}
            className={`roadmap-dropdown ${animationState.classNames}`}
          >
            <div className="closeContainer">
              <button onClick={startStopAnimation} className="close">
                ✕
              </button>
            </div>
            <ol className={`roadmap-list ${animationState.classNames}`}>
              <li>
                <a onClick={() => executeScroll(1)}>Pre-Mint</a>
              </li>
              <li>
                <a onClick={() => executeScroll(2)}>Mint</a>
              </li>
              <li>
                <a onClick={() => executeScroll(3)}>Sellout</a>
              </li>
              <li>
                <a onClick={() => executeScroll(4)}>Airdrops</a>
              </li>
              <li>
                <a onClick={() => executeScroll(5)}>DAO</a>
              </li>
              a
            </ol>
            <div className="roadmap-details">
              <RoadMap />
              {/* <Airdrops /> */}
              <div className="roadmap-main">
                <div className="roadmap-container">
                  <div className="roadmap-strip">
                    <div className="roadmap-strip-head">Phase 4</div>
                    <div ref={fifth} className="roadmap-strip-subHead">
                      Collective Art DAO
                    </div>
                    <div className="roadmap-strip-desc">
                      <p>
                        We will begin building a revolutionary DAO that will
                        help finance traditional artists and bridge physical
                        artwork to the digital world.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {animationState["mint-tab"] && (
          <div
            onAnimationEnd={onAnimationEnd}
            className={`roadmap-dropdown roadmap-mint-bg ${animationState.classNames} `}
          >
            <div className="closeContainer">
              <button onClick={startStopAnimation} className="close">
                ✕
              </button>
            </div>
            <div className="mint-section-wrapper">
              <div className="mint-top">
                <div className="mint-top-content">
                  <div className="calc-img-container">
                    <div className="calc-total-supply">
                      <p>{calcMessage}</p>
                    </div>
                    <div className="button-group">
                      <div
                        onClick={handleDecrement}
                        onMouseDown={() => setDecrementClicked(true)}
                        onMouseUp={() => setDecrementClicked(false)}
                        className="decrement-mint-button-container"
                      >
                        <div className="decrement-mint-button-flex">
                          {decrementClicked ? (
                            <img
                              className="decrement-mint-button"
                              src={minusDown}
                            />
                          ) : (
                            <img
                              className="decrement-mint-button"
                              src={minusUp}
                            />
                          )}
                        </div>
                      </div>

                      <div className="calc-mint-amount">
                        <p>{mintAmount}</p>
                      </div>
                      <div
                        onClick={handleIncrement}
                        onMouseDown={() => setIncrementClicked(true)}
                        onMouseUp={() => setIncrementClicked(false)}
                        className="increment-mint-button-container"
                      >
                        <div className="increment-mint-button-flex">
                          {incrementClicked ? (
                            <img
                              className="increment-mint-button"
                              src={plusDown}
                            />
                          ) : (
                            <img
                              className="increment-mint-button"
                              src={plusUp}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      // onClick={() => setCalcMessage("80085")}
                      onMouseDown={() => {
                        setCalcMessage("80085");
                        setMintClicked(true);
                      }}
                      onMouseUp={() => {
                        setCalcMessage("1234 / 1234");
                        setMintClicked(false);
                      }}
                      className="mintButton"
                    >
                      <div className="mintUpContainer">
                        <div>
                          {mintClicked ? (
                            <img className="calc-mint-button" src={mintDown} />
                          ) : (
                            <img className="calc-mint-button" src={mintUp} />
                          )}
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {animationState["team-tab"] && (
          <div
            onAnimationEnd={onAnimationEnd}
            className={`roadmap-dropdown roadmap-team-bg ${animationState.classNames}`}
          >
            <div className="closeContainer">
              <button onClick={startStopAnimation} className="close">
                ✕
              </button>
            </div>

            <div className="bio-one">
              <div className="bio-content">
                <span>Kyle Boyea</span>
                <a
                  className="twitter-link"
                  target="_blank"
                  href="https://twitter.com/uneeddabae"
                >
                  @uneeddabae
                </a>
                <span>Artist / Developer</span>
              </div>
            </div>
            <div className="bio-two">
              <div className="bio-content">
                <span>Caleb Fowler</span>
                <a
                  target="_blank"
                  className="twitter-link"
                  href="https://twitter.com/ctfcaleb"
                >
                  @ctfcaleb
                </a>
                <span>Developer / Marketing</span>
              </div>
            </div>
          </div>
        )}
      </>
      {/* -------------------------------- NAVIGATION BAR -------------------------------- */}
      <nav>
        <div className="container">
          <span className="nav-banner">
            <img id="navLogo" src={rgbanner} />
          </span>
          <div className="nav-collapse">
            <div className="nav-links">
              <a
                name="roadmap-tab"
                onClick={startStopAnimation}
                className="nav-items"
                href
              >
                ROADMAP
              </a>
              <a
                name="mint-tab"
                onClick={startStopAnimation}
                className="nav-items"
              >
                MINT
              </a>
              {/* <a  href="#about" className="nav-items">
                ABOUT
              </a> */}
              <a
                name="team-tab"
                onClick={startStopAnimation}
                className="nav-items"
              >
                TEAM
              </a>
            </div>
          </div>
          <div className="social-links-nav">
            <a target="_blank" href="https://discord.gg/ycUSZvYTDX">
              <img className="social-img" src={discord} />
            </a>
            <a
              target="_blank"
              href="https://twitter.com/TheRoundGuysNFT"
              style={{ padding: "0vw 1vw" }}
            >
              <img className="social-img twitter-img" src={twitter} />
            </a>
          </div>
        </div>
      </nav>

      {/* -------------------------------- HERO SECTION -------------------------------- */}
      <div className="clouds-gif">
        {/* <div className="dot"></div> */}
        <div
          className={`${roundGuyAnimation.classNames && "roundGuyPeeker"} ${
            roundGuyAnimation.classNames
          }`}
        ></div>

        <div className="landing-page-container">
          <img
            // onMouseEnter={showRoundGuy}
            // onMouseLeave={hideRoundGuy}
            id="mainLogo"
            src={rgbanner}
          />
          <div className="landing-text-container">
            The Round Guys is the genesis project of a series of NFT collections
            to come. Owning a Round Guy gains you exclusive access to future
            airdrops from renowned street artists.
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
